import React, { useState, useEffect, useContext } from "react";
import { useEthers, useContractCall, useNotifications } from "@usedapp/core";
import { utils, ethers } from "ethers";
import { useWallet } from "../hooks/useWallet";
import { BigNumber, FixedNumber } from "ethers";
import AppContext from "../context/AppContex";
import { useBuyTokens } from "../hooks/index";
import Polygon from "../assets/polygon-token.svg";
import Logo from "../assets/logo.png";

import MaticLogo from "../assets/matic.png";
import RlcLogo from "../assets/rlc-coin.png";

export default function UserLayout(props: any) {
  const { account, keyAddress } = useWallet();
  const [checkedItems, setCheckedItems] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountRLC, setAmountRLC] = useState("");
  const [rate, setRate] = useState("");
  const [balanceMatic, setBalanceMatic] = useState(0);
  const [balanceRLC, setBalanceRLC] = useState(0);
  const [buttonAccount, setbuttonAccount] = useState(true);
  const {
    state,
    CallContractRLC,
    buyTokens,
    addAlert,
    addRLC,
    getBalance,
    getBalanceRLC,
  } = useContext(AppContext);
  const { usd, rlc } = state;
  const { notifications } = useNotifications();

  useEffect(() => {
    haddleRate();
  }, []);

  useEffect(() => {
    if (keyAddress) {
      setbuttonAccount(false);
      haddleBuy();
      console.log("Realizar compra");
    }
  }, [keyAddress]);

  useEffect(() => {
    if (account) {
      setbuttonAccount(false);
      getBalanceWallet();
    } else {
      setbuttonAccount(true);
    }
    console.log(account);
  }, [account]);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account !== "") {
      const etherBalance = await getBalance(account);
      const etherBalanceRLC = await getBalanceRLC(account);
      console.log(etherBalanceRLC);
      console.log(etherBalance);
      setBalanceMatic(etherBalance);
      setBalanceRLC(etherBalanceRLC);
    }
  };
  /*
  useEffect(() => {
    if (response) {
      // console.log(response)
      if (response?.status === "Exception") {
        addAlert({
          status: "error",
          message: response.errorMessage,
          visible: true,
        });
      }
      if (response?.status === "Mining") {
        addAlert({
          status: "info",
          message:
            "Transaction in progress hash: " + response.transaction?.hash,
          visible: true,
        });
      }
      if (response?.status === "Success") {
        addAlert({
          status: "success",
          message: "Transaction Completed: " + response.transaction?.hash,
          visible: true,
        });
      }
    }
  }, [response]);
  */

  const haddleBuy = async () => {
    if (!amount) return;
    var trx: any = "";
    setbuttonAccount(true);
    addAlert({
      status: "info",
      message: "Transaction in progress",
      visible: true,
    });
    console.log("Account is");
    console.log(account);
    console.log("keyAddress is");
    console.log(keyAddress);
    trx = await buyTokens(account, utils.parseEther(amount), keyAddress)
      .then((response: any) => {
        addAlert({
          status: "success",
          message: "Transaction Completed: " + response.transactionHash,
          visible: true,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 10000);
      })
      .catch((error: any) => {
        console.log(error);
        addAlert({
          status: "error",
          message: "Insufficient funds for gas * price + value",
          visible: true,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
      });
  };
  const haddleRate = async () => {
    CallContractRLC()
      ._rate()
      .then((data: any) => {
        console.log(data);
        let amountFixed = FixedNumber.from(data, "fixed80x18")
          .divUnsafe(FixedNumber.from("100000000", "fixed80x18"))
          .toString();
        setRate(amountFixed);
        addRLC(amountFixed);
      });
  };

  const changeAmountMATIC = (e: any) => {
    let calculate =
      parseFloat(e) >= 0 ? (parseFloat(usd) * parseFloat(e)) / rlc : 0;
    setAmountRLC(calculate.toString());
    setAmount(e);
  };

  const changeAmountRLC = (e: any) => {
    let calculate =
      parseFloat(e) >= 0 ? (parseFloat(e) * rlc) / parseFloat(usd) : 0;
    setAmount(calculate.toString());
    setAmountRLC(e);
  };

  return (
    <>
      <div className="card-body">
        <label htmlFor="matic" className="float-left">
          <strong>From</strong>
        </label>
        <div className="input-group input-group-lg pb-2">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-transparent"
              id="inputGroup-sizing-lg"
            >
              <img
                src={MaticLogo}
                alt="MATIC"
                width="20px"
                height="20px"
                style={{ marginRight: "5px" }}
              />{" "}
              MATIC
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            value={amount}
            onChange={(e) => changeAmountMATIC(e.target.value)}
          />
        </div>
        <div className="input-group input-group-sm  ">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-transparent"
              id="inputGroup-sizing-sm"
            >
              Balance:
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            disabled
            value={balanceMatic}
          />
        </div>
        <div>
          <i
            className="bi bi-arrow-down-short"
            style={{ fontSize: "2rem", color: "cornflowerblue" }}
          ></i>
        </div>
        <label htmlFor="RLC" className="float-left ">
          <strong>To (estimate):</strong>
        </label>
        <div className="input-group input-group-lg ">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-transparent"
              id="inputGroup-sizing-lgs"
            >
              <img
                src={RlcLogo}
                alt="RLC"
                width="20px"
                height="20px"
                style={{ marginRight: "5px" }}
              />
              RLC
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            value={amountRLC}
            onChange={(e) => changeAmountRLC(e.target.value)}
          />
        </div>
        <div className="input-group input-group-sm mt-2">
          <div className="input-group-prepend">
            <span
              className="input-group-text bg-transparent"
              id="inputGroup-sizing-sma"
            >
              Balance:
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            aria-label="small"
            aria-describedby="inputGroup-sizing-sm"
            disabled
            value={balanceRLC}
          />
        </div>
      </div>
      {account && (
        <div className="card-footer bg-transparent border-0">
          <button
            id="redline_signBrowser"
            className="btn btn-primary w-100"
            style={{ fontSize: "18px" }}
            disabled={parseFloat(amountRLC) <= 0 ? true : false}
          >
            Buy Token with Matic
          </button>
        </div>
      )}
    </>
  );
}
