import { Button, Box, Text } from "@chakra-ui/react";
//import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import { useWallet } from "../hooks/useWallet";
import { useEffect, useState, useContext } from "react";
import AppContext from "../context/AppContex";
type Props = {
  handleOpenModal: any;
};
export default function ConnectButton({ handleOpenModal }: Props) {
  const { account } = useWallet();
  const [balance, setBalance] = useState("");
  const { getBalance }: any = useContext(AppContext);

  useEffect(() => {
    getBalanceWallet();
  }, [account]);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account !== "") {
      const etherBalance = await getBalance(account);
      console.log(etherBalance);
      setBalance(etherBalance);
    }
  };

  const disconnectWallet = () => {
    localStorage.removeItem("accountAddress");
    window.location.reload();
  };
  return account ? (
    <p
      onClick={() => disconnectWallet()}
      style={{ color: "#0069d9", cursor: "pointer" }}
    >
      Disconnect Wallet{" "}
      {account &&
        `${account.slice(0, 6)}...${account.slice(
          account.length - 4,
          account.length
        )}`}
    </p>
  ) : (
    <button
      id="redline_getBrowser"
      className="btn btn-primary w-100"
      style={{ fontSize: "18px" }}
    >
      Connect Wallet
    </button>
  );
}
