import {
  ChakraProvider,
  useDisclosure,
  Flex,
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import AdminLayout from "./components/AdminLayout";
import PriceUSD from "./components/priceUSD";
import UserLayout from "./components/UserLayout";
import AlertLayout from "./components/AlertLayout";
import "@fontsource/inter";
import "../src/theme/style.css";
import React, { useState, useEffect, useContext } from "react";

import AppContext from "./context/AppContex";
import useInitialState from "./hooks/useInitialState";
import RlcLogo from "./assets/rlc-coin.png";
function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialState = useInitialState();
  return (
    <AppContext.Provider value={initialState}>
      <div className="cover-container d-flex w-100  p-3 mx-auto flex-column">
        <main className="px-3 m-auto">
          <PriceUSD />
          <div className="card text-center mt-3 border-1 bg-white shadow-lg rounded p-2">
            <div className="card-header card-title bg-transparent border-0">
              <img
                src={RlcLogo}
                width="70"
                height="70"
                className="d-inline-block align-top"
                alt=""
              />
              <h1 className="h3 mt-2">Swap Matic for RLC</h1>
            </div>
            <UserLayout />
            <div className="card-footer bg-transparent border-0">
              <ConnectButton handleOpenModal={onOpen} />
              <p className="mt-3 text-center">
                *Transferring using other networks may result in token loss.
              </p>
            </div>
          </div>
        </main>
        <footer className="mt-auto"></footer>
      </div>
      <ChakraProvider>
        <AlertLayout />
      </ChakraProvider>
    </AppContext.Provider>
  );
}

export default App;
