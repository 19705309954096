import React, { useState, useEffect, useContext } from "react";
import { Button, Box, Text } from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import AppContext from "../context/AppContex";

import { BigNumber, FixedNumber } from "ethers";

type Props = {
  handleOpenModal: any;
};

export default function PriceUSD(props: any) {
  const { state, addUSD, addRLC, CallContractChainlink } =
    useContext(AppContext);
  const { usd, rlc } = state;
  const [priceUSD, setpriceUSD] = useState(0);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    CallContractChainlink()
      .latestRoundData()
      .then((roundData: any) => {
        let amount = Number(roundData["answer"]);
        let amountFixed = FixedNumber.from(roundData["answer"], "fixed80x18")
          .divUnsafe(FixedNumber.from("100000000", "fixed80x18"))
          .toString();
        console.log(amountFixed);
        setpriceUSD(amount);
        addUSD(amountFixed);
      });
    console.log(blink);
    setBlink((blink) => !blink);
  }, []);

  /*

  useEffect(() => {
      CallContractChainlink()
        .latestRoundData()
        .then((roundData: any) => {
          let amount = Number(roundData["answer"]);
          let amountFixed =  FixedNumber.from(roundData["answer"], "fixed80x18")
            .divUnsafe(FixedNumber.from("100000000", "fixed80x18"))
            .toString();
          console.log(amountFixed);
          setpriceUSD(amountFixed);
          addUSD( amountFixed);
          setBlink(false);
        });
      console.log(blink);
      
    
  }, []);*/

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="xl"
      ml={{ base: 0, md: 3 }}
      color="#ee3434"
      my={{ base: 1, md: 0 }}
      flexDirection="column"
    >
      <Box p="2">
        <Text color="#ee3434" fontSize="md">
          1 RLC ≈{" "}
          <span className={`${blink ? "blink_me" : ""}`}>{rlc} USD</span>
        </Text>
      </Box>
    </Box>
  );
}
