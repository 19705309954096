import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import AppContext from "../context/AppContex";
export default function AlertLayout() {
  const { state } = useContext(AppContext);
  const { alert } = state;
  const [alertComponent, setalertComponent] = useState(false);

  useEffect(() => {
    console.log(alert);
    if (alert.visible) {
      setalertComponent(true);
      setTimeout(() => {
        setalertComponent(false);
      }, 100000);
    }
  }, [alert]);

  const closedAlert = () => {
    setalertComponent(false);
  };
  return (
    <>
      {alertComponent && (
        <Box w={{ base: '100%', md: '60%' }} position="absolute" bottom="10px" borderRadius="md">
          <Alert status={alert?.status}>
            <AlertIcon />
            <Box flex="1">
              <AlertDescription display="block">
                {alert?.message}
              </AlertDescription>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={(e) => closedAlert()}
            />
          </Alert>
        </Box>
      )}
    </>
  );
}
