import { useState } from "react";
import initialState from "../initialState";
import { utils, ethers } from "ethers";
import ChainlinkABI from "../abi/Chainlink.json";
import RLCCrowdsaleABI from "../abi/RLCCrowdsale.json";
import { erc20Abi } from "../abi/abi";
import Web3 from "web3";
const {
  REACT_APP_NEWTORK_MAINET,
  REACT_APP_RLC,
  REACT_APP_CONTRACT_CHAINLINK,
  REACT_APP_CONTRACT_ICO,
} = process.env;

const webProvider = new Web3(
  new Web3.providers.HttpProvider(
    REACT_APP_NEWTORK_MAINET //process.env.REACT_APP_INFURA
  )
); // eslint-disable-line

const useInitialState = () => {
  const [state, setState] = useState(initialState);

  const addUSD = (payload) => {
    /*setState({
      ...state,
      usd: payload,
    });*/
    setState((prevState) => {
      return {
        ...prevState,
        usd: payload,
      };
    });
  };

  const addRLC = (payload) => {
    console.log(state);
    /* setState({
      ...state,
      rlc: payload,
    });*/
    setState((prevState) => {
      return {
        ...prevState,
        rlc: payload,
      };
    });
  };

  const addAlert = (payload) => {
    setState({
      ...state,
      alert: payload,
    });
  };

  const CallContractChainlink = () => {
    const provider = new ethers.providers.JsonRpcProvider(
      REACT_APP_NEWTORK_MAINET
    );
    const addr = REACT_APP_CONTRACT_CHAINLINK;
    const priceFeed = new ethers.Contract(addr, ChainlinkABI, provider);
    return priceFeed;
  };

  const CallContractRLC = () => {
    const provider = new ethers.providers.JsonRpcProvider(
      REACT_APP_NEWTORK_MAINET
    );
    const addr = REACT_APP_CONTRACT_ICO;
    let accounts = provider.listAccounts();
    console.log(accounts);
    const signer = provider.getSigner();
    console.log(signer);
    const priceFeed = new ethers.Contract(addr, RLCCrowdsaleABI, provider);
    return priceFeed;
  };

  const getBalance = async (accountAddress) => {
    if (!accountAddress) return;
    let balanceTemp = await webProvider.eth.getBalance(accountAddress);
    balanceTemp =
      balanceTemp !== 0 ? webProvider.utils.fromWei(balanceTemp, "ether") : 0;
    return balanceTemp;
  };

  const getBalanceRLC = async (accountAddress) => {
    let tokenAddress = REACT_APP_RLC;
    let walletAddress = accountAddress;
    let contract = new webProvider.eth.Contract(erc20Abi, tokenAddress);
    let balance = await contract.methods
      .balanceOf(walletAddress)
      .call(function (error, result) {
        return result;
      });
    balance = balance !== 0 ? webProvider.utils.fromWei(balance, "ether") : 0;
    return balance;
  };

  const buyTokens = async (address, amount, privateKey) => {
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(address);
    const tokenAddress = REACT_APP_CONTRACT_ICO;
    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(
      RLCCrowdsaleABI,
      tokenAddress
    );

    const data = contract.methods.buyTokens(address).encodeABI();
    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      value: amount,
      data,
      from: address,
      to: tokenAddress,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  return {
    CallContractChainlink,
    CallContractRLC,
    addUSD,
    addAlert,
    addRLC,
    getBalance,
    getBalanceRLC,
    buyTokens,
    state,
  };
};

export default useInitialState;
